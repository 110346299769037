import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    loginUrl = environment.login_url;
    constructor(private translate: TranslateService, private cookieService: CookieService) {
        let currentLang = 'en';
        if (this.cookieService.get('_currentLang')) {
            currentLang = this.cookieService.get('_currentLang');
        }
        translate.setDefaultLang(currentLang);
        translate.use(currentLang);

        this.cookieService.set('_currentLang', currentLang);
        this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
    }

}
