import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class UtilsService {
    notification = new BehaviorSubject<any>('');
    transferNotification = new BehaviorSubject<any>(true);
    roleId: number;
    userInfo: any;

    isLogoutTriggered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLogoutTriggered$: Observable<boolean> = this.isLogoutTriggered.asObservable();
    isLogoutTriggerCompleted = false;

    showNotificationOnTop(data: any) {
        this.notification.next(data);
    }

    updateTransferNotifications(data: any) {
        this.transferNotification.next(data);
    }

    deepClone = (val: any) => JSON.parse(JSON.stringify(val));

    constructor() {
        this.isLogoutTriggered.next(false);
        this.isLogoutTriggerCompleted = false;
    }


    /**
     * Covnert minutes to format HH:MM
     * @param minutes
     */
    getHHMMFormat(minutes) {
        const hours = Math.floor(minutes ? minutes / 60 : 0);
        const minute = minutes ? minutes % 60 : 0;
        return _.padStart(hours, 2, '0') + ':' + _.padStart(minute, 2, '0');
    }

    convertWeekDaysToNumber(weekDay: any) {
        let dayNumber = 0;
        switch (weekDay) {
            case 'MONDAY':
                dayNumber = 1;
                break;
            case 'TUESDAY':
                dayNumber = 2;
                break;
            case 'WEDNESDAY':
                dayNumber = 3;
                break;
            case 'THURSDAY':
                dayNumber = 4;
                break;
            case 'FRIDAY':
                dayNumber = 5;
                break;
            case 'SATURDAY':
                dayNumber = 6;
                break;
        }
        return dayNumber;
    }


    GetSortOrder(prop) {
        return function (a, b) {
            if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
                return 1;
            } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
                return -1;
            }
            return 0;
        };
    }

    randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    AfterHour(hour: number, date: Date) {
        const time = hour * 1000 * 60 * 60;
        const now = new Date();
        if (now.getTime() - date.getTime() > time) {
            return true;
        }
        return false;
    }

    generateFirst7Character() {
        let text = '';
        const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 7; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    getFirstDayOfGivenMonth(month: any) {
        const date = new Date();
        const year = date.getFullYear();
        return new Date(year, month, 1);
    }

    getLastDayOfGivenMonth(month: any) {
        const date = new Date();
        const year = date.getFullYear();
        return new Date(year, month + 1, 0);
    }

    generateFirst6Character() {
        let text = '';
        const possible = '0123456789';
        for (let i = 0; i < 6; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    generateByNumber(number: any) {
        let text = '';
        const possible = '0123456789';
        for (let i = 0; i < number; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
}
