export class Guess {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    childClientUsername: string;
    childClientPassword: string;
    password: string;
    phone: string;
    dateOfBirth: any;
    gender: string;
    phoneCode: string;
    status: any;
    companyId: any;
    referenceBy: string;
    assignProject: any = [];
    fakeCode: string;
    companyName: string;
    projectName: string;

    accountType: string;
    serviceType: string;
    serviceTypes: [];
    servicePlan: string;
    addressOne: string;
    city: string;
    state: string;
    zipcode: string;

    paymentCardType: string;
    cardDataStr: string;
    payload: any;
    passwordMD5: string;
}
