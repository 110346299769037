const server_ip = 'https://prod2-api.operrwork.com';
const v2_server_ip = 'https://prod2-api.operrwork.com';

export const environment = {
    production: true,
    server_backend: server_ip,
    server_ip: `${server_ip}/api`,
    v2_server_backend: `${v2_server_ip}`,
    v2_server_ip: `${v2_server_ip}`,
    ip_checking_site: 'https://api.ipify.org/?format=json',
    time_to_display_clock: 15,
    v2_api_url: 'https://prod2-api.operrwork.com/company/api/v2',
    login_url: 'https://login.operrwork.com',
};
