<div class="container-fluid px-0 shadow d-flex align-items-center bg-darkblue" id="headerArea">
    <div class="container-lg m-auto text-center">
        <div class="row align-items-center justify-content-start">
            <div class="col-lg-12 text-left px-5">
                <div class="text-light" id="headerText">Plan Checkout</div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid px-0 shadow d-flex align-items-center pb-5">
    <div class="container-lg m-auto">
		<div class="ui-g mt-5">
			<div class="ui-g-12">
				<h1 class="m-0 ml-2 mt-4">Primary Contact</h1>
				<div class="ml-2 mb-4">The person you would like us to contact in case of issues.</div>
			</div>
		</div>
		<p-panel [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>Contact Info</span>
			</p-header>
			<div class="ui-g pb-3">
				<div class="ui-g-6 ui-sm-12">
					<label>First Name</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="John">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Last Name</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="Doe">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Title</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user-tie" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="Campaign Manager">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Mobile Number</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-mobile-phone" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="">
					</div>
				</div>
				<div class="ui-g-4 ui-sm-12">
					<label>Office Number</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-phone" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="">
					</div>
				</div>
				<div class="ui-g-2 ui-sm-12">
					<label>Ext.</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-phone" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="x000">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Email</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-at" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="example@operr.com">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Confirm Email</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-at" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="example@operr.com">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Campaign Type</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-folder-tree" style="width: 15px;"></i>
							</button>
						</div>
						<select class="form-control chosen-select input-md" style="height: 40px;">
							<option value=""></option>
							<option value="">Federal Campaign</option>
							<option value="">Federal PAC</option>
							<option value="">Presidential</option>
							<option value="">International</option>
							<option value="">Association</option>
							<option value="">Corporate PAC</option>
							<option value="">State Campaign</option>
							<option value="">State PAC</option>
							<option value="">State Party</option>
							<option value="">State Party-Federal</option>
							<option value="">Other</option>
						</select>
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Name of Campaign</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-quote-left" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="John Doe 2024">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Campaign Legal Entity</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-quote-left" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="Example Campaign, LLC.">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Campaign Office Address</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-signs-post" style="width: 15px;"></i>
							</button>
						</div>
						<input type="phone" class="form-control" placeholder="123 Example Street, New York NY 10011">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>State</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-map-location-dot" style="width: 15px;"></i>
							</button>
						</div>
						<select class="form-control chosen-select input-md" name="StateCode" id="StateCode" style="height: 40px;">
							<option value=""></option>
							<option value="AL">Alabama</option>
							<option value="AK">Alaska</option>
							<option value="AZ">Arizona</option>
							<option value="AR">Arkansas</option>
							<option value="CA">California</option>
							<option value="CO">Colorado</option>
							<option value="CT">Connecticut</option>
							<option value="DE">Delaware</option>
							<option value="DC">District of Columbia</option>
							<option value="FL">Florida</option>
							<option value="GA">Georgia</option>
							<option value="HI">Hawaii</option>
							<option value="ID">Idaho</option>
							<option value="IL">Illinois</option>
							<option value="IN">Indiana</option>
							<option value="IA">Iowa</option>
							<option value="KS">Kansas</option>
							<option value="KY">Kentucky</option>
							<option value="LA">Louisiana</option>
							<option value="ME">Maine</option>
							<option value="MD">Maryland</option>
							<option value="MA">Massachusetts</option>
							<option value="MI">Michigan</option>
							<option value="MN">Minnesota</option>
							<option value="MS">Mississippi</option>
							<option value="MO">Missouri</option>
							<option value="MT">Montana</option>
							<option value="NE">Nebraska</option>
							<option value="NV">Nevada</option>
							<option value="NH">New Hampshire</option>
							<option value="NJ">New Jersey</option>
							<option value="NM">New Mexico</option>
							<option value="NY">New York</option>
							<option value="NC">North Carolina</option>
							<option value="ND">North Dakota</option>
							<option value="OH">Ohio</option>
							<option value="OK">Oklahoma</option>
							<option value="OR">Oregon</option>
							<option value="PA">Pennsylvania</option>
							<option value="RI">Rhode Island</option>
							<option value="SC">South Carolina</option>
							<option value="SD">South Dakota</option>
							<option value="TN">Tennessee</option>
							<option value="TX">Texas</option>
							<option value="UT">Utah</option>
							<option value="VT">Vermont</option>
							<option value="VA">Virginia</option>
							<option value="WA">Washington</option>
							<option value="WV">West Virginia</option>
							<option value="WI">Wisconsin</option>
							<option value="WY">Wyoming</option>
	 					</select>
					</div>
				</div>
			</div>
		</p-panel>
		<div class="ui-g mt-5">
			<div class="ui-g-12">
				<h1 class="m-0 ml-2 mt-4">Payment Details</h1>
				<div class="ml-2 mb-4">Please provide your payment details</div>
			</div>
		</div>
		<p-panel [toggleable]="true">
			<p-header class="flex-grow-1">
				<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>Payment Info</span>
			</p-header>
			<div class="ui-g pb-3">
				<div class="ui-g-6 ui-sm-12">
					<label>Cardholder (Full Name)</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="John Doe">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Billing Street Address</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-signs-post" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="321 Example Street">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Billing Zip Code</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-location-dot" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="10011">
					</div>
				</div>
				<div class="ui-g-6 ui-sm-12">
					<label>Card Number</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-credit-card" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="10011">
					</div>
				</div>
				<div class="ui-g-3 ui-sm-12">
					<label>Exp Date</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-calendar-xmark" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="mm/yy">
					</div>
				</div>
				<div class="ui-g-3 ui-sm-12">
					<label>CVV Code</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-hashtag" style="width: 15px;"></i>
							</button>
						</div>
						<input type="text" class="form-control" placeholder="000">
					</div>
				</div>
			</div>
		</p-panel>
		<div class="ui-g">
			<div class="ui-g-12 text-center py-5">
				<a class="btn btn-danger btn-lg mx-1" routerLink="/home">
					<span><i class="fa-solid fa-xmark mr-2"></i>Cancel</span>
				</a>
				<a class="btn btn-primary btn-lg mx-1">
					<span><i class="fa-solid fa-check mr-2"></i>Continue<i class="fa-solid fa-chevron-right ml-2"></i></span>
				</a>
			</div>
		</div>
	</div>
</div>
