<div class="container-fluid main-gradient shadow" style="position: sticky;top: 0px;z-index:999">
    <div class="container-lg m-auto">
        <div class="d-flex flex-row align-items-center justify-content-between flex-wrap py-1">
            <a class="navbar-brand d-flex align-items-center" href="./index.html" style="padding: 10px 0px;">
                <div class="d-flex flex-column">
                    <img src="assets/images/logo-slim-new.png" class="logo">
                </div>
            </a>
            <div class="main-menu">
                <ul class="d-flex flex-row me-auto mb-1 mb-lg-0" style="column-gap: 15px;font-size: 16px !important">
                    <li class="nav-item d-flex align-items-center">
                        <svg class="svg-inline--fa fa-house text-warning me-2" aria-hidden="true" focusable="false"
                             data-prefix="fas" data-icon="house" role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 576 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"></path>
                        </svg>
                        <a class="nav-link" href="./index.html"> Home</a>
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <svg class="svg-inline--fa fa-circle-check text-warning me-2" aria-hidden="true"
                             focusable="false" data-prefix="fas" data-icon="circle-check" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"></path>
                        </svg>
                        <a class="nav-link" href="./index.html##benefits"> Benefits</a>
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <svg class="svg-inline--fa fa-person-booth text-warning me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="person-booth" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                  d="M256 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V192h64V32zm320 0c0-17.7-14.3-32-32-32s-32 14.3-32 32V480c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM224 512c17.7 0 32-14.3 32-32V320H192V480c0 17.7 14.3 32 32 32zM320 0c-9.3 0-18.1 4-24.2 11s-8.8 16.3-7.5 25.5l31.2 218.6L288.6 409.7c-3.5 17.3 7.8 34.2 25.1 37.7s34.2-7.8 37.7-25.1l.7-3.6c1.3 16.4 15.1 29.4 31.9 29.4c17.7 0 32-14.3 32-32c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM112 80c0-26.5-21.5-48-48-48S16 53.5 16 80s21.5 48 48 48s48-21.5 48-48zm0 261.3V269.3l4.7 4.7c9 9 21.2 14.1 33.9 14.1H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H157.3l-41.6-41.6c-14.3-14.3-33.8-22.4-54-22.4C27.6 160 0 187.6 0 221.6v55.7l0 .9V480c0 17.7 14.3 32 32 32s32-14.3 32-32V384l32 42.7V480c0 17.7 14.3 32 32 32s32-14.3 32-32V421.3c0-10.4-3.4-20.5-9.6-28.8L112 341.3z"></path>
                        </svg>
                        <a class="nav-link" href="./index.html#features"> Features</a>
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <i class="fa-solid fa-dollar-sign text-warning me-1"></i>
                        <a class="nav-link" href="#plan"> Pricing</a>
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <svg class="svg-inline--fa fa-location-dot text-warning me-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"></path>
                        </svg>
                        <a class="nav-link" href="./index.html#contact-us.html"> Contact</a>
                    </li>
                    <li class="nav-item d-flex align-items-center" style="cursor: pointer;">
                        <a class="nav-link" routerLink="/sign-up"> Sign up</a>
                    </li>
                    <li class="nav-item d-flex align-items-center">
                        <a class="btn btn-warning m-4" href="https://login.operrwork.com" target="_blank">
                            <span class="text-nowrap"><svg class="svg-inline--fa fa-lock me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor"
                                                                                                                                                                                                                                                                  d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"></path></svg>
                           User Login <svg class="svg-inline--fa fa-chevron-right ms-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img"
                                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path
                                        fill="currentColor"
                                        d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path></svg>
							</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="dropdown">
                <button class="btn btn-light btn-lg dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <svg class="svg-inline--fa fa-bars" aria-hidden="true" focusable="false" data-prefix="fas"
                         data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                         data-fa-i2svg="">
                        <path fill="currentColor"
                              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
                    </svg><!-- <i class="fa-solid fa-bars"></i> Font Awesome fontawesome.com -->
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="nav-link m-2" aria-current="page" href="./index.html#benefits">
                        <svg class="svg-inline--fa fa-circle-plus text-warning mr-2" aria-hidden="true"
                             focusable="false" data-prefix="fas" data-icon="circle-plus" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                  d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"></path>
                        </svg>
                        <!-- <i class="fa-solid fa-circle-plus text-warning mr-2"></i> Font Awesome fontawesome.com -->
                        Benefits</a></li>
                    <li><a class="nav-link m-2" aria-current="page" href="./index.html#tracking">
                        <svg class="svg-inline--fa fa-location-dot text-warning mr-2" aria-hidden="true"
                             focusable="false" data-prefix="fas" data-icon="location-dot" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                  d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"></path>
                        </svg>
                        <!-- <i class="fa-solid fa-location-dot text-warning mr-2"></i> Font Awesome fontawesome.com -->
                        Tracking</a></li>
                    <li><a class="nav-link m-2" aria-current="page" href="./index.html#compliance">
                        <svg class="svg-inline--fa fa-scale-unbalanced text-warning mr-2" aria-hidden="true"
                             focusable="false" data-prefix="fas" data-icon="scale-unbalanced" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                  d="M522.1 62.4c16.8-5.6 25.8-23.7 20.2-40.5S518.6-3.9 501.9 1.6l-113 37.7C375 15.8 349.3 0 320 0c-44.2 0-80 35.8-80 80c0 3 .2 5.9 .5 8.8L117.9 129.6c-16.8 5.6-25.8 23.7-20.2 40.5s23.7 25.8 40.5 20.2l135.5-45.2c4.5 3.2 9.3 5.9 14.4 8.2V480c0 17.7 14.3 32 32 32H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V153.3c21-9.2 37.2-27 44.2-49l125.9-42zm-396.3 211c.4-.8 1.3-1.3 2.2-1.3s1.7 .5 2.2 1.3L204.9 416H51.1l74.7-142.7zM128 224c-18.8 0-36 10.4-44.7 27L5.5 399.5c-3.1 5.8-6.1 14-5.5 23.8c.7 12.1 4.8 35.2 24.8 55.1C45.1 498.6 77.8 512 128 512s82.9-13.4 103.2-33.5c20-20 24.2-43 24.8-55.1c.6-9.8-2.5-18-5.5-23.8L172.7 251c-8.7-16.6-25.9-27-44.7-27zm384-80c.9 0 1.7 .5 2.2 1.3L588.9 288H435.1l74.7-142.7c.4-.8 1.3-1.3 2.2-1.3zm-44.7-21L389.5 271.5c-3.1 5.8-6.1 14-5.5 23.8c.7 12.1 4.8 35.2 24.8 55.1C429.1 370.6 461.8 384 512 384s82.9-13.4 103.2-33.5c20-20 24.2-43 24.8-55.1c.6-9.8-2.5-18-5.5-23.8L556.7 123C548 106.4 530.8 96 512 96s-36 10.4-44.7 27z"></path>
                        </svg>
                        <!-- <i class="fa-solid fa-scale-unbalanced text-warning mr-2"></i> Font Awesome fontawesome.com -->
                        Labor Law</a></li>
                    <li><a class="nav-link m-2" aria-current="page" href="./index.html#campaign-tools.html">
                        <svg class="svg-inline--fa fa-person-booth text-warning mr-2" aria-hidden="true"
                             focusable="false" data-prefix="fas" data-icon="person-booth" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                  d="M256 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V192h64V32zm320 0c0-17.7-14.3-32-32-32s-32 14.3-32 32V480c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM224 512c17.7 0 32-14.3 32-32V320H192V480c0 17.7 14.3 32 32 32zM320 0c-9.3 0-18.1 4-24.2 11s-8.8 16.3-7.5 25.5l31.2 218.6L288.6 409.7c-3.5 17.3 7.8 34.2 25.1 37.7s34.2-7.8 37.7-25.1l.7-3.6c1.3 16.4 15.1 29.4 31.9 29.4c17.7 0 32-14.3 32-32c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM112 80c0-26.5-21.5-48-48-48S16 53.5 16 80s21.5 48 48 48s48-21.5 48-48zm0 261.3V269.3l4.7 4.7c9 9 21.2 14.1 33.9 14.1H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H157.3l-41.6-41.6c-14.3-14.3-33.8-22.4-54-22.4C27.6 160 0 187.6 0 221.6v55.7l0 .9V480c0 17.7 14.3 32 32 32s32-14.3 32-32V384l32 42.7V480c0 17.7 14.3 32 32 32s32-14.3 32-32V421.3c0-10.4-3.4-20.5-9.6-28.8L112 341.3z"></path>
                        </svg>
                        <!-- <i class="fa-solid fa-person-booth text-warning mr-2"></i> Font Awesome fontawesome.com -->
                        Campaign Tools</a></li>
                    <li><a class="nav-link m-2" aria-current="page" href="./index.html#contact-us.html">
                        <svg class="svg-inline--fa fa-mobile text-warning mr-2" aria-hidden="true" focusable="false"
                             data-prefix="fas" data-icon="mobile" role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                  d="M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z"></path>
                        </svg>
                        <!-- <i class="fa-solid fa-mobile-phone text-warning mr-2"></i> Font Awesome fontawesome.com -->
                        Contact</a></li>
                    <li><a class="nav-link m-2" aria-current="page" href="{{loginUrl}}" target="_blank">
                        <svg class="svg-inline--fa fa-up-right-from-square text-warning mr-2" aria-hidden="true"
                             focusable="false" data-prefix="fas" data-icon="up-right-from-square" role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                            <path fill="currentColor"
                                  d="M288 32c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L306.7 128 169.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L352 173.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V64c0-17.7-14.3-32-32-32H288zM80 64C35.8 64 0 99.8 0 144V400c0 44.2 35.8 80 80 80H336c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"></path>
                        </svg>
                        <!-- <i class="fa-solid fa-up-right-from-square text-warning mr-2"></i> Font Awesome fontawesome.com -->
                        User Login</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>

<div class="container-fluid bg-darkblue px-0 shadow d-flex align-items-center" id="footer">
    <div class="container-lg m-auto text-center py-5">

        <div class="row align-items-top my-5 px-4 py-5">
            <div class="col-lg-3 col-sm-12 text-start">
                <h3 class="text-light m-0">Operr Work</h3>

                <img src="assets/images/operr-icon.png" style="width: 100%;max-width: 150px" class="my-4">
                <p style="font-size: 14px" class="text-light">(c) Copyright 2024 - Operr Group, Inc. All Rights
                    Reserved.</p>

            </div>

            <div class="col-lg-3 col-sm-12 text-start text-light">
                <h3 class="text-light m-0 mb-4">Operr Group</h3>
                <ul>
                    <li><a class="text-light" href="https://www.operrtel.com" target="_blank">Operr Tel</a></li>
                    <li><a class="text-light" href="https://www.operr.com" target="_blank">Operr Platform</a></li>
                    <li><a class="text-light" href="https://www.operrbilling.com" target="_blank">Operr Billing</a></li>
                    <li><a class="text-light" href="https://www.operrgroup.com" target="_blank">Operr Group</a></li>
                    <li><a class="text-light" href="https://www.wwlo.us" target="_blank">WWLO.us</a></li>
                </ul>
            </div>
            <div class="col-lg-3 col-sm-12 text-start text-light">
                <h3 class="text-light m-0 mb-4">Contact Us</h3>
                <ul>
                    <li>Sales: 1 (929) 201-1899</li>
                    <li>support@operrwork.com</li>
                    <li>30-50 Whitestone Expwy.<br>Suite 402<br>Flushing NY 11354</li>
                </ul>
            </div>
            <div class="col-lg-3 col-sm-12 text-start">
                <h3 class="text-light m-0 mb-4">Our Office</h3>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.621353499978!2d-73.83663360000001!3d40.7703521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2600161146f1d%3A0xcdb80dc9115d4449!2s30-50%20Whitestone%20Expy%2C%20Queens%2C%20NY%2011354!5e0!3m2!1sen!2sus!4v1685555053878!5m2!1sen!2sus"
                        width="250" height="200" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</div>
