import {Injectable} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';

@Injectable()
export class TranslateCustomeService {

    constructor(private translatePipe: TranslatePipe) {
    }


    getValue(key: any, args: any[]) {
        const options = [];
        if (args && args.length > 0) {
            args.forEach(arg => {
                options.push(arg.key, this.translatePipe.transform(arg.value));
            });
        }
        return this.translatePipe.transform(key, options);
    }

    get(key: any) {
        return this.translatePipe.transform(key);
    }


}
