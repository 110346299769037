import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from './../../environments/environment';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnDestroy, OnInit {

    contentList: any[] = [];
    isMonthly = true;

    constructor(protected _http: HttpClient, private router: Router,) {
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.getAllPlan().subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const data = resObj.data;
                for (const con of data) {
                    this.contentList.push(con);
                }
            } else {
                this.contentList = [];
            }
        });
    }

    getAllPlan() {
        return this._http.get(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/get-all-plan`).pipe(map(res => res));
    }

    signUp() {
        this.router.navigate(['/', 'sign-up']);
    }
}