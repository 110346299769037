<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="spinner-overlay" *ngIf="isLoading">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<form [formGroup]="guessForm" #guessDataForm [name]="guessDataForm" class="card p-0 add-shadow login mx-auto"
      style="max-width: 740px !important;">
    <div class="ui-g p-4 pb-0" style="text-align: center; display: inline;">
        <img src="../../../assets/images/logo.png" style="width: 135px;height: auto;"><br>
        <h1 class="m-0" translate>{{'Create an Account' | translate}}</h1>
    </div>
    <div class="ui-g p-4">
        <div class="ui-g-12">
            <div *ngIf="guessForm.get('email').errors?.userTaken" class="alert alert-danger alert-dismissible fade show"
                 role="alert">
                <span class=""><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}
                    : </strong> {{'EmailExistingInSystem' | translate}}</span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div *ngIf="guessForm.get('username').errors?.userTaken"
                 class="alert alert-danger alert-dismissible fade show" role="alert">
                <span class=""><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}
                    : </strong> {{'validate.thisFieldNotAvailable' | translate:{value: 'username' | translate} }}</span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <div *ngIf="guessForm.controls['password'].errors?.pattern && guessForm.controls['password'].dirty"
                 class="alert alert-warning alert-dismissible fade show" role="alert">
                <span class=""><strong><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Alert' | translate}}
                    : </strong> {{'password_pattern_required' | translate}}</span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'firstName' | translate}}
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="(guessForm.controls['first_name'].invalid)&& guessForm.controls['first_name'].touched && (guessForm.controls['first_name'].hasError('required'))"
                          style="font-size: 12px">{{'Required' | translate:{value: 'firstName' | translate} }}
                    </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-user"></i>
                    </button>
                </div>
                <input type="text" pInputText formControlName="first_name" [(ngModel)]="guess.firstName"
                       placeholder="{{'firstName' | translate}}" class="w-100">
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'lastName' | translate}}
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="(guessForm.controls['last_name'].invalid) && guessForm.controls['last_name'].touched && (guessForm.controls['last_name'].hasError('required'))"
                          style="font-size: 12px">{{'Required' | translate:{value: 'lastName' | translate} }}
                    </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-lock"></i>
                    </button>
                </div>
                <input type="text" pInputText placeholder="{{'lastName' | translate}}" formControlName="last_name"
                       [(ngModel)]="guess.lastName" class="w-100">
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label for="email">{{'Email' | translate}}
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="guessForm.controls['email'].errors?.pattern && guessForm.controls['email'].dirty"
                          style="font-size: 12px">{{'Required' | translate:{value: 'Email' | translate} }}
                    </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-at"></i>
                    </button>
                </div>
                <input type="text" pInputText formControlName="email" [(ngModel)]="guess.email"
                       placeholder="example@operr.com" class="w-100">
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'username' | translate}} (8 chars min)
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="(guessForm.controls['username'].invalid)&& guessForm.controls['username'].touched && (guessForm.controls['username'].hasError('required'))"
                          style="font-size: 12px">{{'Required' | translate:{value: 'username' | translate} }}
                    </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-user-shield"></i>
                    </button>
                </div>
                <input type="text" pInputText formControlName="username" [(ngModel)]="guess.username" autocomplete="off"
                       placeholder="{{'username' | translate}}" (keydown.space)="$event.preventDefault()" class="w-100">
            </div>
            <span class="text-danger" *ngIf="guessForm.get('username').errors?.minLength"
                  style="font-size: 12px">{{'validate.minlength' | translate:{value: 8} }}</span>
        </div>

        <div class="ui-g-6 ui-sm-12">
            <label>{{'password' | translate}}
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="(guessForm.controls['password'].invalid)  && guessForm.controls['password'].touched && (guessForm.controls['password'].hasError('required'))"
                          style="font-size: 12px">{{'Required' | translate:{value: 'password' | translate} }}
                    </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-lock"></i>
                    </button>
                </div>
                <input *ngIf="showPwd" pInputText type="text" id="password" formControlName="password"
                       [(ngModel)]="guess.password" name="password" placeholder="{{'Password' | translate}}"
                       class="w-100"/>
                <input *ngIf="!showPwd" pInputText type="password" id="password" formControlName="password"
                       [(ngModel)]="guess.password" name="password" placeholder="{{'Password' | translate}}"
                       class="w-100"/>
                <div class="input-group-append">
                    <button *ngIf="!showPwd" (click)="showPassword()" class="btn btn-white" id="button-addon2"
                            type="button" style="cursor:default !important">
                        <i class="fa-solid fa-eye text-primary"></i>
                    </button>
                    <button *ngIf="showPwd" (click)="showPassword()" class="btn btn-white" id="button-addon2"
                            type="button" style="cursor:default !important">
                        <i class="fa-solid fa-eye-slash text-danger"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="ui-g-6 ui-sm-12">
            <label>{{'Country Code' | translate}}
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="guessForm.controls['phone_code'].invalid && guessForm.controls['phone_code'].touched && guessForm.controls['phone_code'].hasError('required')"
                          style="font-size: 12px">{{'Required' | translate:{value: 'Code' | translate} }}
                    </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-hashtag"></i>
                    </button>
                </div>
                <p-dropdown [options]="listOfPhoneCode" [filter]="true" formControlName="phone_code"
                            placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="guess.phoneCode">
                    <ng-template pTemplate="item" let-item>
                        <span class="text-truncate">{{item.label | translate}}</span>
                    </ng-template>
                    <ng-template let-item pTemplate="selectedItem">
                        <span class="one-liner">{{item.label | translate}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>

        <div class="ui-g-6 ui-sm-12">
            <label>{{'PhoneNumber' | translate}}
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="(guessForm.controls['phone'].invalid)&& guessForm.controls['phone'].touched && (guessForm.controls['phone'].hasError('required'))"
                          style="font-size: 12px">{{'validate.required' | translate:{value: 'PhoneNumber' | translate} }}
                </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-mobile-phone"></i>
                    </button>
                </div>
                <p-inputMask mask="(999)999-9999" formControlName="phone" placeholder="{{'PhoneNumber' | translate}}"
                             [(ngModel)]="guess.phone" class="w-100"></p-inputMask>
            </div>
        </div>
        <div class="ui-g-6 ui-sm-12">
            <label>{{'Service Type' | translate}}
                <span class="required-indicator">*
                    <span class="text-danger"
                          *ngIf="(guessForm.controls['serviceType'].invalid)&& guessForm.controls['serviceType'].touched && (guessForm.controls['serviceType'].hasError('required'))"
                          style="font-size: 12px">{{'validate.required' | translate:{value: 'Service Type' | translate} }}
                    </span>
                </span>
            </label>
            <div class="input-group w-100">
                <div class="input-group-prepend p-0">
                    <button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
                        <i class="fa-solid fa-folder-tree"></i>
                    </button>
                </div>
                <p-dropdown [options]="serviceTypeList" formControlName="serviceType"
                            placeholder="{{'Select One' | translate}}" [(ngModel)]="guess.serviceType">
                    <ng-template pTemplate="item" let-item>
                        <span class="text-truncate">{{item.label | translate}}</span>
                    </ng-template>
                    <ng-template let-item pTemplate="selectedItem">
                        <span class="one-liner">{{item.label | translate}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
    </div>
    <div class="card-footer p-4 d-flex align-items-center justify-content-between">
        <a [routerLink]="['/home']" class="btn btn-danger mx-1"><i
                class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</a>
        <button type="button" (click)="createGuess(guessDataForm)" class="btn btn-primary mx-1"><span
                class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Submit' | translate}}</span></button>
    </div>
</form>


<p-dialog [showHeader]="true" *ngIf="showPolicy" [(visible)]="showPolicy" modal="modal" [dismissableMask]="true"
          showEffect="fade" class="deparment-history" [style]="{'width':'80%'}">
    <p-header>
        <span><i class="fa-solid fa-info mr-2"></i>Terms and Privacy Policy</span>
    </p-header>
    <h2>TERMS OF SERVICE / USER AGREEMENT</h2>
    <h3>
        Terms and Pronouns
    </h3>
    <div>
        The following terms shall be applicable throughout the entirety of this Agreement.
        Us, Our, Ours, We and Operr Work refer to Operr Work Inc. and Operr Work Technologies.
        You, Yours, Merchant(s), User(s), Buyer(s), Member(s) and Customer(s) refers to anyone legitimately using the
        Operr Work platform.
        The Mobile Application Terms of Use, all terms and additional policies published on and in our Services are
        incorporated into this User Agreement. Please read our Terms of Service so you understand what’s up with your
        use of Operr Work. You agree to our Terms of Service (“Terms”) by installing, accessing, or using our apps,
        Services, features, software, or website (Collectively, “Services”).
    </div>
    <ul>
        <li>Who you are?</li>
        <li>What matters to you.</li>
        <li>What you do.</li>
        <li>How you do it.</li>
    </ul>
    <h3>I. Overview</h3>
    <div>
        Our Operr Work Rules or The Terms of Use combine all documents/sections/rules that are listed below. The Operr
        Work Terms of Use (Operr Work Terms for short) is a legally binding contract between you and Operr Work. Once
        you have activities, Pay or participation under any means, you will be bound by Operr Work Terms.
        Operr Work Terms will outline your duties and your rights when you participate on Operr Work or use any Services
        that Operr Work provides. By participating on Operr Work under any means or using any of our Services, you are
        agreeing to these terms.
    </div>
    <div>
        In the event that you disagree with any of Our Terms contained herein, then you must cease using Operr Work.
    </div>
    <div>
        Please read the agreement carefully as our Operr Work Terms Section entitle Disputes, defines the way we, at
        Bynfor, handle any dispute you may have with Bynfor. In Section Disputes, by agreeing with Operr Work Terms and
        using Bynfor service, you agree to resolve all disputes through binding individual arbitration, which means that
        you agree to bring claims against Operr Work in your one and only individual capacity; you will waive any right
        to have those disputes decided by a judge or jury, which means you cannot bring claims against Operr Work as
        plaintiff or member of a class action, and for that, you agree to waive your right to participate in class
        actions, class arbitration, or representative action.
    </div>
</p-dialog>
