import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {AppRoutes} from './app.routes';
import 'rxjs/add/operator/toPromise';
import './rxjs.operators';
import {AngularDraggableModule} from 'angular2-draggable';
import {WebcamModule} from 'ngx-webcam';
import {CookieService} from 'ngx-cookie-service';
import {AppComponent} from './app.component';
import {DndModule} from 'ng2-dnd';
import {DropdownModule, InputMaskModule, MessageService, PanelModule, ProgressSpinnerModule, SharedModule, ToastModule} from 'primeng';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule, TranslatePipe, TranslateService} from '@ngx-translate/core';
import {NgxImageCompressService} from 'ngx-image-compress';
import {SpreadsheetAllModule} from '@syncfusion/ej2-angular-spreadsheet';
import {CountdownModule} from 'ngx-countdown';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AgmDrawingModule} from '@agm/drawing';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {InterceptHeaderHttps} from './intercept.header.https';
import {SignUpComponent} from './sign-up/sign-up.component';
import {UtilsService} from './service/utils.service';
import {NotificationService} from './service/notification.service';
import {MembershipService} from './service/membership.service';
import {FormValidatorService} from './service/form-validator.service';
import {WindowRefService} from './service/window-ref.service';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import {TranslateCustomeService} from './service/translate-custome.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        NgxDaterangepickerMd.forRoot(),
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AppRoutes,
        BrowserAnimationsModule,
        SharedModule,
        WebcamModule,
        AngularDraggableModule,
        ToastModule,
        NgIdleKeepaliveModule.forRoot(),
        DndModule.forRoot(),
        SpreadsheetAllModule,
        CountdownModule,
        ScrollingModule,
        AngularFireDatabaseModule,
        AgmDrawingModule,
        PanelModule,
        DropdownModule,
        InputMaskModule,
        ProgressSpinnerModule
    ],
    declarations: [
        AppComponent,
        SignUpComponent,
        HomeComponent,
        CheckoutComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptHeaderHttps,
            multi: true
        },
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        CookieService,
        TranslateService,
        TranslatePipe,
        NgxImageCompressService,
        UtilsService,
        NotificationService,
        MembershipService,
        FormValidatorService,
        WindowRefService,
        TranslateCustomeService,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
